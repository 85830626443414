import React, { useEffect, useState } from "react";
import "./global.css";
import Container from "./Container";
import { css } from "@emotion/react";
import LogofromImagesFolder from "../images/icon.png";
import Title from "./TitleComponent";
import { UserStateContext } from "../pages";

const About = (props) => {
  return (
    <>
      <UserStateContext.Consumer>
        {(choose) => {
          return (
            <Container>
              <div css={main}>
                <img
                  src={LogofromImagesFolder}
                  alt="sumadhra"
                  style={{ width: "195px", height: "80px" }}
                />
                <div>
                  <Title
                    title="Adarsh Park Heights"
                    size="1.5vw"
                    color="#000"
                    case="uppercase"
                  />
                  <p>
                    Adarsh Park Heights could be a luxury high-rise flat project
                    arising in Gunjur Village metropolis. Adarsh Park heights
                    could be a position project from Adarsh cluster itself, Over
                    the past twenty years Adarsh has delivered over forty Homes
                    wherever thousands of happy customers reside. Adarsh Park
                    heights is comming with 1BHk, 2BHK & 3BHK residences with
                    totally different dimensions. It is'designed in such a way
                    that it's give exceptional life experiences, security and
                    value. Adarsh properties have seen absolutely the best rate
                    of capital appreciation Associate in Nursingd have given our
                    customers Associate in Nursing quality to possess for all
                    times. With innovation, quality, and property at the core of
                    our work and a singular client central approach has place
                    us on the league of the best and most bound developers
                    at intervals the business. Our experience in land
                    acquisition, understanding the patron wishes, execution
                    capabilities beside the appointment of architects,
                    designers, project managers together with exceptional sales
                    and once sales service has helped us to be counted among
                    the forerunners in assets development.
                  </p>
                </div>
              </div>
            </Container>
          );
        }}
      </UserStateContext.Consumer>
    </>
  );
};

export default About;

const main = css`
  text-align: center;
  p {
    text-align: justify;
    padding: 10px 20px;
    color: #000;
    letter-spacing: 0.02vw;
    line-height: 1.5;
    font-weight: 450;
    font-size: 16px;
  }
`;
