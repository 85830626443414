import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "./global.css";
import LocationComponet from "./locationPageParkHeights";
import FixedFooter from "./Footerfixed";
import Container from "./Container";
import { css } from "@emotion/react";
import LogofromImagesFolder from "../images/icon.png";
import Title from "./TitleComponent";
import Sidemenu from "./SideMenu";
import SEO from "./SEO";

const Layout = (props) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);
  return (
    <>
      <SEO title={props.title} />
      <Navbar />
      <Sidemenu />
      <>{props.children}</>
      <LocationComponet />
      <Container>
        <div css={main}>
          <img
            src={LogofromImagesFolder}
            alt="sumadhra"
            style={{ width: "190px", height: "80px" }}
          />
          <div>
            <p>
              We operate with a belief that everybody deserves a far better
              life. Over the past twenty years Adarsh has delivered quite forty
              comes wherever thousands of happy customers reside. Our comes
              square measure designed to produce exceptional life experiences,
              security and price. Adarsh properties have seen the best rate of
              capital appreciation Associate in Nursingd have given our
              customers an quality to have for all times. With innovation,
              quality, and property at the core of our work and a singular
              client centrical approach has place USA on the league of the most
              effective and most trustworthy developers within the business. Our
              experience in land acquisition, understanding the client wants,
              execution capabilities together with the appointment of
              architects, designers, project managers beside exceptional sales
              and once sales service has helped USA to be counted among the
              forerunners in assets development.
            </p>
          </div>
        </div>
      </Container>
      <div css={before}>
        <b>Disclaimer</b> : The content is for information purposes only and
        does not constitute an offer to avail of any service. Prices mentioned
        are subject to change without notice and properties mentioned are
        subject to availability. Images for representation purposes only. This
        is the official website of authorized marketing partner. We may share
        data with RERA registered brokers/companies for further processing. We
        may also send updates to the mobile number/email id registered with us.
        All Rights Reserved.
      </div>

      <div css={lastBefore}>
        <p>
          ©{" "}
          <a href="https://livexcellence.in" target="_blank">
            LivExcellence Real Estate Services
          </a>
          . All Rights Reserved
        </p>
      </div>
      <div css={offset > 250 ? one : two}>
        <FixedFooter />
      </div>
    </>
  );
};

export default Layout;

const main = css`
  text-align: center;
  p {
    text-align: justify;
    padding: 10px 20px;
    color: #000;
    letter-spacing: 0.02vw;
    line-height: 1.5;
    font-weight: 450;
    font-size: 16px;
    @media (max-width: 600px) {
      font-weight: 400;
      padding: 10px;
    }
  }
`;

const before = css`
  font-size: 11px;
  padding: 10px 40px;
  @media (max-width: 600px) {
    padding: 10px;
    text-align: center;
  }
`;

const lastBefore = css`
  background-color: #050505;
  padding: 10px 0 10px;
  font-size: 15px;
  text-align: center;
  margin-bottom: 2.1rem;
  line-height: 24px;
  color: #f3f3f3;
  a {
    color: white !important;
    text-decoration: none;
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media (max-width: 600px) {
    font-size: 11px;
    padding: 1px;
  }
`;

const one = css`
  display: block;
`;
const two = css`
  display: none;
`;
